<template>
  <!--资讯搜索结果-->
  <div class="advisory mr0At" style="width: 1200px;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>资讯搜索</p>
    </div>
    
    <div class="w100 h100 difb res_box">
      <!-- 左侧 -->
      <div class="news_left" style="width: 600px;">
        <!--左侧新闻列表-->
        <div v-for="(item,index) in res" :key="index" style="margin-bottom: 37px;" class="pointer"
         @click="goDetail(item.id)">
          <!--标题-->
          <div class="fs20 col333 tlt one_overflow" style="margin-bottom: 14px;">{{item.title}}</div>
          <!--图片-->
          <div class="dif alc news_img tlt" style="margin-bottom: 13px;">
            <!--<img class="bgcb">
            <img class="bgcb">
            <img class="bgcb">-->
            <img :src="item2" class="one_img" v-for="(item2,index2) in item.images" :key="index2">
          </div>
          <!--来源 时间-->
          <div class="dif alc posr">
            <div style="font-size: 12px;color:#999;">{{item.siteName}}</div>
            <div style="font-size: 12px;color:#999;" class="mrLft10">{{item.createtime}}</div>
            <div style="font-size: 12px;color:#999;" class="pointer mrLft30 mrRit30" @click.stop="setShare(item,index)">分享</div>
            <div class="share_icon" v-if="showShare===index" @click.stop @mouseleave="showShare=-1">
              <share :config="config"></share>
            </div>
            <div style="font-size: 12px;color:#999;" class="pointer" @click.stop="collect(item)" v-if="item.is_Collection==0">收藏资讯</div>
            <div style="font-size: 12px;color:#3765FF;" class="pointer" @click.stop="collect(item)" v-else>取消收藏</div>
          </div>
        </div>
        <div v-if="res.length<=0" style="padding-top: 100px;">
          <el-result icon="info" title="未搜索到结果"></el-result>
        </div>
      </div>
     <!-- &lt;!&ndash;搜索结果左侧列表&ndash;&gt;
      <div class="res_left pdLft30 w100">
        <ul class="w100" v-if="res.length!==0">
          <li class="res_item mrTop30 box posr" v-for="(item,index) in res" style="width: 560px;">
            <div class="no">{{index+1}}<span>.NO</span></div>
            &lt;!&ndash;详细信息&ndash;&gt;
            <div class="left_center dif alc mrTop10">
              &lt;!&ndash;图片&ndash;&gt;
              <router-link :to="{path:'/search/advisory/detail',query:{'id':item.id}}">
                <div class="img_warp" style="width: 174px;height: 104px;">
                  <img :src="item.image" class="w100 h100">
                </div>
              </router-link>
              &lt;!&ndash;标题 信息&ndash;&gt;
              <div class="difcb w70 box pdLft10 pdTop10" style="height: 100px;">
                <router-link :to="{path:'/search/advisory/detail',query:{'id':item.id}}">
                  <h3 style="color:#7b7b7b;font-weight:normal;" class="one_overflow tlt">{{item.title}}</h3>
                  <p class="two_overflow tlt col7b fs14">{{item.content}}</p>
                </router-link>
                <div class="a_info dif alc posr">
                  <p class="fs12 col666 tlt pdRit30 one_overflow" style="width: 100px;">买购网官网买购网官网买购网官网买购网官网</p>
                  <p class="fs12 col666 tlt pdRit30">{{getDate(item.createtime)}}</p>
                  <p class="fs12 col666 tlt pdRit30">{{getHM(item.createtime)}}</p>
                  <div class="posr a_share">分享
                    <div class="dif">
                      <img src="@/assets/image/enterprise/icon_qq.png">
                      <img src="@/assets/image/enterprise/icon_wx.png" class="mrLft10 mrRit10">
                      <img src="@/assets/image/enterprise/icon_wb.png">
                    </div>
                  </div>
                  <div class="a_collect">收藏资讯</div>
                </div>
              </div>
            </div>

          </li>
        </ul>
        <div class="w100 tct fs20" style="margin-top: 50px;" v-else>暂无数据</div>
      </div>-->
      <hot></hot>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";

export default {
  name: "advisory",
  components: {Hot},
  data() {
    return {
      type: '',//分类
      keyword: '',//搜索关键字,
      res: [],//搜索结果
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: 0,
      page:1
    }
  },
  created() {
    this.showShare=-1
    let token = this.$store.state.token
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword
    this.keyword = keyword
    let type = this.$route.query.type
    this.type = type
    this.list = []
    this.page = 1
    this.search(token,keyword,type)
    this.onScrollBottom();
  },
  watch:{
    //监听路由参数  搜索
    "$route.params":function (route){
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type
      let token = this.$store.state.token
      this.search(token,this.keyword,this.type)
    }
  },
  methods:{
    goDetail(id) { //跳转到详情
      this.$router.push({
        path: '/search/advisory/detail',
        query: {
          id: id
        }
      })
    },
    //收藏资讯
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 2
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          let token = this.$store.state.token
          this.search(token,this.keyword,this.type)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    setShare(item,index) { //设置分享的配置
      if(this.showShare===index) {
        this.showShare=-1
      }else {
        this.showShare=index
        this.$axios("POST","/api/site_title/addNum",{id: item.id})
      }
      this.config.url = window.location.protocol + "//" + window.location.host + '/search/advisory/detail?id=' + item.id //不能分享localhost
      if(item.images && item.images.length>0) {
        this.config.image = this.$store.state.url + item.images[0]
      }
      this.config.title = item.title
      this.config.description = item.keyword
    },
    search(token,keyword,type){ //搜索
      this.$message.info('努力加载中')
      this.$axios("POST",'/api/search/search',{
        keyword,
        token,
        type,
        page:this.page
      }).then(res => {
        if(res.code == '1') {
          this.res = [...this.res,...res.data]
        }
      })
    },
    onScrollBottom() {
      console.log(0)
      //滚动到最底部
      let that = this;
      window.onscroll = function() {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if(window.innerWidth>1200){
          if (scrollTop + windowHeight >= scrollHeight) {
          console.log(1)

            //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
            // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
              that.page++;
              that.search(that.$store.state.token,that.keyword,that.type);
          }
        }else{
          if (scrollTop >= windowHeight*that.page) {
          console.log(2)

              that.page++;
              that.search(that.$store.state.token,that.keyword,that.type);
          }
        }
      };
    },
  },
  
  computed: {
    getTime() { //获取上传时间
      return function (time) {
        var date = new Date(time*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return M + D + h + m + s;
      }
    }
  }
}
</script>

<style scoped>

/*分享*/
.a_share div{
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
}
.a_share div img{
  width: 20px;
  height: 20px;
}
.share_icon {
  position: absolute;
  top: 15px;
  left: 170px;
  background-color: #ffffff;
}
/*左侧收藏*/
.news_img img{
  width: 184px;
  height: 122px;
  background: #cbcbcb;
  margin-right: 4px;
}
.news_img img:first-child{
  border-radius: 10px 0 0 10px;
}
.news_img img:last-child{
  border-radius: 0 10px 10px 0;
  margin-right: 0;
}

.news_img img.one_img{
  border-radius: 10px;
}

/*序号*/
.no span{
  font-weight: normal;
  font-size: 10px;
}
</style>